<template>
    <div class="page-sms-gateway-queues">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Queues</h1>

                    <app-loader v-if="loading" fixed-on="desktop" />

                    <div class="board">
                        <app-error v-model="errors.show" :message="errors.message" />

                        <g-chart
                            v-show="!is_empty"

                            :data="statistics"

                            type="ColumnChart"
                            :settings="settings"
                            :options="options"

                            :createChart="createChart"

                            @ready="onChartReady"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GChart } from 'vue-google-charts'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        GChart,

        appLoader,
        appError,
    },

    data() {
        return {
            chart: null,
            charts_lib: null,

            queues: {
                "Modica High": 0,
                "Modica Medium": 0,
                "Modica Low": 0,
                "SMSC High": 0,
                "SMSC Medium": 0,
                "SMSC Low": 0,
                "Chatbot High": 0,
                "Chatbot Medium": 0,
                "Chatbot Low": 0,
            },

            queues_to_check: {
                "SendToModica": "Modica",
                "SendToSMSC": "SMSC",
                "SendToChatbot": "Chatbot",
            },

            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.getQueues()
        },

        getQueues() {
            this.loading = true

            let promises = []

            for (const queueName in this.queues_to_check) {
                const queueDisplayNamePrefix = this.queues_to_check[queueName]
                const onePromise = this.$store.dispatch('api_smsgw/GetQueueStats', { SPID: this.current_spid, QueueName: queueName, TimeIntervalInSeconds: 86400 }).then(apiresp => {
                    this.queues[`${queueDisplayNamePrefix} High`]   = apiresp.QueueLengthHigh
                    this.queues[`${queueDisplayNamePrefix} Medium`]   = apiresp.QueueLengthMedium
                    this.queues[`${queueDisplayNamePrefix} Low`]   = apiresp.QueueLengthLow
                }).catch(error => {
                    console.log('Error api_smsgw/GetQueueStats on '+queueName+' / '+queueDisplayNamePrefix)
                    console.error(error)
                    this.errors.message = errMessage(error)
                })
                promises.push(onePromise)            
            } 

            Promise.all(promises)
                .then(() => {
                })
                .finally(() => {
                    this.loading = false
                })

            // const delay_in_ms = 1000

            // setTimeout(() => {
            //     this.queues = TEST_QUEUES

            //     this.loading = false
            // }, delay_in_ms)
        },

        createChart(el, google) {
            this.chart = new google.charts.Bar(el)

            return this.chart
        },

        onChartReady(chart, google) {
            this.charts_lib = google
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        is_empty() {
            return this.queues.length == 0
        },

        statistics() {
            const statistics = [['Queue', 'Length']]

            for (const name in this.queues) {
                statistics.push([`${ name }`, this.queues[name]])
            }

            return statistics
        },

        settings() {
            return {
                packages: ['bar'],
            }
        },

        options() {
            let options = null

            if (this.charts_lib) {
                options = this.charts_lib.charts.Bar.convertOptions({
                    height: 440,

                    bars: 'vertical',

                    series: {
                        0: {
                            color: '#e60000',
                            visibleInLegend: false,
                        },
                    },

                    legend: 'none',

                    vAxis: {
                        baselineColor: '#ccc',
                        format: '#,### SMS',
                        gridlines: {
                            color: '#ccc',
                        },
                        textStyle: {
                            color: '#999'
                        },
                        viewWindow: {
                            min: 0,
                        },
                        title: 'SMS Count in Queue',
                    },
                    hAxis: {
                        textStyle: {
                            color: '#999'
                        },
                        // gridlines: {
                        //     color: '#e8e8e8',
                        //     count: 4,
                        // },
                        title: '',
                    },
                })
            }

            return options
        },
    },
}
</script>

<style lang="scss">
.page-sms-gateway-queues {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 40px;
        }
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);
    }
}

@media (max-width: $mobile-size) {
    .page-sms-gateway-queues {
        padding-bottom: 64px;

        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .board {
            padding: 24px 8px;
        }
    }
}
</style>